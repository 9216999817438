export function fetchDataWithChunks({ totalCount, limit, fetchCallback, resultCallback, chunkSize = 10 }) {
    const totalPages = Math.ceil(totalCount / limit);
    const results = [];

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function processChunk(pageStart, pageEnd) {
        const chunkResults = [];
        for (let page = pageStart; page < pageEnd && page <= totalPages; page++) {
            const result = await fetchCallback(limit, page);
            const processedResult = resultCallback(result);
            chunkResults.push(...processedResult);
        }
        return chunkResults;
    }

    async function fetchAllData() {
        for (let i = 0; i < totalPages; i += chunkSize) {
            const pageStart = i + 1;
            const pageEnd = i + 1 + chunkSize;
            const chunkResults = await processChunk(pageStart, pageEnd);
            results.push(...chunkResults);
            await delay(1000);
        }
        return results;
    }

    return fetchAllData();
}
