import React, { useState } from "react";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import { chunk } from "lodash";
import Table from "../../../../../../base/components/Table";
import { columns } from "./components";

const DAYS_IN_WEEK = 7;

export function WeeklyProgramPlan({ days = [] }) {
  const [open, setOpen] = useState([]);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen([]);
    } else {
      setOpen([id]);
    }
  }

  return <div className="mt-5">
          <Accordion flush open={open} toggle={toggle}>
            {chunk((days), DAYS_IN_WEEK).map((week, index) => (
              <AccordionItem
                key={`week-${index}`}
                id={index.toString()}
              >
                <AccordionHeader
                  className="white-bg-accordion-header"
                  targetId={index.toString()}>
                  <span className="text-secondary font-weight-semibold">WEEK {index + 1}</span>
                </AccordionHeader>

                <AccordionBody accordionId={index.toString()}>
                    <div className="w-100 bg-body p-3 border-bottom border-1 font-weight-semibold border-secondary">
                        General
                    </div>
                    <WeekTable days={week} />
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
  </div>
}

function WeekTable({ days }) {
  return <Table
    columns={columns}
    data={days}
    withoutPagination
    tableClassName="h-auto min-h-auto overflow-x-visible"
  />;
}