import React from 'react';
import { Container } from 'reactstrap';
import { Navigate, Route, Routes } from 'react-router-dom';

import { HEALTH_PROGRAM_GROUP_LINKS, HEALTH_PROGRAM_GROUP_ROUTES } from './config';
import Fitness from "./Fitness";
import NutritionPrograms from "./Nutrition";

export default function HealthProgram() {
    return (
        <Container fluid>
            <Routes>
                <Route
                    path={HEALTH_PROGRAM_GROUP_ROUTES.FITNESS}
                    element={<Fitness/>}
                />

                <Route
                    path={HEALTH_PROGRAM_GROUP_ROUTES.NUTRITION}
                    element={<NutritionPrograms/>}
                />

                <Route
                    path={HEALTH_PROGRAM_GROUP_ROUTES.BASE}
                    element={<Navigate to={HEALTH_PROGRAM_GROUP_LINKS.FITNESS} />}
                />
            </Routes>
        </Container>
    );
}