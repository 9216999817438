import React, { useEffect, useState } from "react";

import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Nav,
    NavItem,
    NavLink
} from "reactstrap";
import classNames from "classnames";
import Button from "../../../../../../base/components/Button";
import { chunk } from "lodash";
import { FieldArray, useField } from "formik";
import { BUTTON_COLORS } from "../../../../../../base/components/Button/appearance";
import Title from "../../../../Biomarkers/Details/Title";
import Switch from "../../../../../../base/components/Switch";
import FormikReactSelect from "../../../../../../base/components/FormikReactSelect";
import { PROGRAM_PLAN_CONTENT_TYPE_LABELS, programContentTypeOptions } from "../../../constants";
import { initialProgramDay } from "../form";
import { DROPDOWN_COMPONENTS } from "./components";
import Icon from "../../../../../../base/components/Icon";
import ConfirmPopup from "../../../../../../base/components/ConfirmPopup";

const DAYS_IN_WEEK = 7;

const DEFAULT_FIELD_NAME = "programDays";


export function WeeklyProgramPlan() {
    const [open, setOpen] = useState([]);
    const [deletingDay, updateDeletingDay] = useState(null)

    const [field, { error }, { setValue }] = useField(DEFAULT_FIELD_NAME);

    const showDeleteDayPopup = deletingDay || deletingDay === 0

    const toggle = (id) => {
        if (open.includes(id)) {
            setOpen([]);
        } else {
            setOpen([id]);
        }
    };

    const copyDay = (day) => {
        setValue([...field.value, day])
    }

    const copyWeek = (weekDays) => {
        setValue([...field.value, ...weekDays])
    }

    const handleClickDeleteDay = (dayIndex) => {
        updateDeletingDay(dayIndex)
    }

    const deleteDay = (dayIndex) => {
        setValue(field.value.filter((_, index) => index !== dayIndex))
    }

    return <div className="mt-5">
        <FieldArray name={DEFAULT_FIELD_NAME}>
            {({ push }) => (
                <>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <Title
                            title="Program days (required)"
                        />
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={() => push(initialProgramDay)}

                        >
                            Add day
                        </Button>
                    </div>

                    <Accordion flush open={open} toggle={toggle}>
                        {chunk((field.value), DAYS_IN_WEEK).map((week, index) => (
                            <AccordionItem
                                key={`week-${index}`}
                                id={index.toString()}
                            >
                                <AccordionHeader
                                    className="white-bg-accordion-header"
                                    targetId={index.toString()}>
                                    <span className="text-secondary font-weight-semibold">WEEK {index + 1}</span>
                                </AccordionHeader>

                                <AccordionBody accordionId={index.toString()}>
                                    <Week days={week} index={index} copyDay={copyDay} copyWeek={copyWeek} onDeleteDay={handleClickDeleteDay} />
                                </AccordionBody>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </>
            )}
        </FieldArray>

        {!!error && typeof error === 'string' && <div className="invalid-feedback d-block" >
            {error}
        </div>}

        {showDeleteDayPopup &&
            <ConfirmPopup
                isOpen={showDeleteDayPopup}
                updateIsOpen={updateDeletingDay}
                onSubmit={() => {
                    deleteDay(deletingDay);
                    updateDeletingDay(null);
                }}
                title="Delete day"
                description="Are you sure you want to delete the chosen day?"
                submitBtnText="Delete"
                className="upload-manually__popup"
            />
        }
    </div>;
}

function Week({ days, index, copyWeek, copyDay, onDeleteDay }) {
    const [activeDay, setActiveDay] = useState(0);

    useEffect(() => {
        if (days.length <= activeDay) {
            setActiveDay(days.length - 1)
        }
    }, [days, activeDay])

    return <div>
        <Nav
            tabs
            className="nav-tabs-custom mt-1"
            style={{
                display: "grid",
                gridTemplateColumns: `repeat(${DAYS_IN_WEEK}, 1fr)`
            }}
        >
            {days.map((_, indexDayByWeek) => {
                return (
                    <NavItem key={`day-${(index * DAYS_IN_WEEK) + indexDayByWeek} `}>
                        <NavLink
                            className={classNames(
                                { active: activeDay === indexDayByWeek },
                                "cursor-pointer text-center"
                            )}
                            onClick={() => setActiveDay(indexDayByWeek)}
                        >
                            <span>Day {indexDayByWeek + 1}</span>
                        </NavLink>
                    </NavItem>
                );
            })}
        </Nav>

        <div className="mt-4">
            <Day index={(index * DAYS_IN_WEEK) + activeDay} copyDay={copyDay} onDeleteDay={onDeleteDay}/>
        </div>

        <div
            onClick={() => copyWeek(days)}
            className="text-primary cursor-pointer d-flex align-items-center gap-2 mt-3"
        >
            <Icon icon="copy" />
            Copy week
        </div>
    </div>;
}

function Day({ index, copyDay, onDeleteDay }) {
    const [{ value }, { error }, { setValue }] = useField(`${DEFAULT_FIELD_NAME}[${index}]`);

    const InstantsDropdown = DROPDOWN_COMPONENTS[value?.contentType];

    return <div className="card-body bg-body">
        <label className="w-100 d-flex align-items-center justify-content-between gap-1">
            Day 1 is rest

            <Switch
                state={value?.isRest}
                updateState={() => setValue({
                    ...initialProgramDay,
                    isRest: !value?.isRest
                })}
            />
        </label>

        {
            !value?.isRest &&
            <>
                <label
                    className="w-100 d-flex align-items-center font-weight-normal mt-3 justify-content-between gap-1">
                    Is it optional?

                    <Switch
                        state={value?.isOptional}
                        updateState={() => setValue({
                            ...value,
                            isOptional: !value?.isOptional
                        })}
                    />
                </label>

                <div className="mt-3">
                    <FormikReactSelect
                        label="Content type"
                        placeholder="Select content type"
                        className="w-100"
                        backendError={error}
                        options={programContentTypeOptions}
                        name={`${DEFAULT_FIELD_NAME}[${index}].contentType`}
                        setFieldValue={(_, contentType) => setValue({
                            ...value,
                            contentType,
                            instance: null
                        })}
                    />
                </div>

                <div className="mt-3">
                    {
                        InstantsDropdown &&
                        <InstantsDropdown
                            value={value.instance}
                            onChange={instance => setValue({
                                ...value,
                                instance,
                            })}
                            label={PROGRAM_PLAN_CONTENT_TYPE_LABELS[value?.contentType]}
                            error={error?.instance}
                        />
                    }
                </div>
            </>
        }
        <div className="d-flex align-items-center justify-content-between mt-3">
            <div
                onClick={() => copyDay(value)}
                className="text-primary cursor-pointer d-flex align-items-center gap-2"
            >
                <Icon icon="copy" />
                Copy day
            </div>

            <div
                onClick={() => onDeleteDay(index)}
                className="text-danger cursor-pointer d-flex align-items-center gap-2"
            >
                <Icon icon="trashIcon" />
                Delete
            </div>
        </div>

    </div>;
}