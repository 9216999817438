import Http from "./Http";
import { DEFAULT_LIMIT_AND_OFFSET } from "./BiomarkersService";

class UserResultsService extends Http {
  static $displayName = "UserResultsService";

  async getUserResults(params) {
    return await this.get('/hl7/hl7-objects', { params })
  }

  async getUserResultBySample(sampleCode) {
    return await this.get(`/hl7/hl7-objects/${sampleCode}`)
  }

  async changeResultStatus(id, data){
    return await this.patch(`/hl7/hl7-objects/${id}/status`, data)
  }

  async getResultsList(id, params = DEFAULT_LIMIT_AND_OFFSET){
    return await this.get(`/hl7/hl7-objects/${id}/results`, { params })
  }

  async reprocessHL7(id){
    return await this.patch(`/hl7/hl7-objects/${id}/results`)
  }

  async receivedByLab(id) {
    return await this.post(`/food-intolerance-processing/${id}/received-by-lab`)
  }

  async processFoodData(params) {
    return await this.post(`/food-intolerance-processing/file${params.id ? '/'+params.id: ''}`,
        { fileId: params.fileId },
        { params: { userId: params.userId } }
    )
  }

  async getCsvData(id) {
    return await this.get(`/hl7/hl7-objects/parced-csv/${id}`)
  }
}

export default UserResultsService;
