import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ProgramsService from "../../../../../services/ProgramsService";
import { useService } from "../../../../../base/hooks/useService";
import BaseLayoutWithCard from "../../../../../base/components/BaseLayoutWithCard";
import { PROGRAMS_GROUP_LINKS } from "../config";

import Button from "../../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../../base/components/Button/appearance";

import { useLoading } from "../../../../../base/hooks/useLoading";
import PageSpinner from "../../../../../base/components/PageSpinner";
import ValueWithLabel from "./components/ValueWIthLabel";
import { ENERGY_EQUATION_LABEL } from "../CreateEditProgram/constants";
import RichTextView from "./components/RIchTextView";
import { LIST_TABS } from "../RulesGoalsList/constants";
import { ALLERGEN_TITLES } from "../../../../../base/constants/foods";


export default function ProgramDetails() {
    /**
     * @type {ProgramsService}
     */
    const programsService = useService(ProgramsService);

    const [program, setProgram] = useState(null);
    const [isPredefined, setIsPredefined] = useState(null);

    const [isLoading, { registerPromise }] = useLoading(true);
    const navigate = useNavigate();

    const { id } = useParams();
    const mapProgramRestrictions = (programRestrictions = []) => {
        return programRestrictions.map(item => item?.restrictionName);
    };

    const mapProgramToForm = (program) => {
        return {
            fullName: program.fullName,
            author: program.author,
            energyEquation: program.energyEquation,
            status: program.status,
            restrictions: mapProgramRestrictions(program.programRestrictions),
            shortDescription: program.shortDescription,
            longDescription: program.longDescription,
            image: program?.file?.link,
            allergens: program?.programAllergens?.map(item => ALLERGEN_TITLES[item.allergen]),
            userSegments: program?.programSegments?.map(item => item.segment.fullName),
        };
    };

    const goToRules = () => {
        const queryParams = new URLSearchParams({
            isPredefined: isPredefined,
            activeTab: LIST_TABS.RULES
        }).toString();

        navigate(`${PROGRAMS_GROUP_LINKS.LIST}/${id}/rule-goal?${queryParams}`)
    };
    const goToGoals = () => {
        const queryParams = new URLSearchParams({
            isPredefined: isPredefined,
            activeTab: LIST_TABS.GOALS
        }).toString();

        navigate(`${PROGRAMS_GROUP_LINKS.LIST}/${id}/rule-goal?${queryParams}`)
    };

    const goToEdit = () => {
        const queryParams = new URLSearchParams({ editProgramId: id }).toString();
        navigate(`${PROGRAMS_GROUP_LINKS.CREATE_EDIT}?${queryParams}`)
    };

    useEffect(() => {
        if (id) {
            registerPromise(programsService.getProgramById(id))
                .then((data) => {
                    setProgram(mapProgramToForm(data));
                    setIsPredefined(data.isPredefined)
                });
        }
    }, [id]);

    const breadcrumbs = {
        title: "Program detailed view",
        breadcrumbItems: [
            { title: "Nutrition", link: PROGRAMS_GROUP_LINKS.BASE },
            { title: "Program detailed view" }
        ]
    };

    return <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
        {isLoading
            ? <PageSpinner/>
            : <>
                <section className="d-flex align-items-start justify-content-between mb-4">
                    <label className="font-weight-semibold font-size-15">General information</label>
                    <div className="d-flex gap-3 align-items-center">
                        <Button
                            color={BUTTON_COLORS.primaryOutline}
                            onClick={goToRules}
                            className="d-flex align-items-center"
                        >
                            View rules
                        </Button>
                        <Button
                            color={BUTTON_COLORS.primaryOutline}
                            onClick={goToGoals}
                            className="d-flex align-items-center"
                        >
                            View nutritional goals
                        </Button>
                        <Button
                            color={BUTTON_COLORS.primary}
                            onClick={goToEdit}
                            className="d-flex align-items-center"
                        >
                            <i className="bx bx-edit-alt me-2"/>
                            Edit program
                        </Button>
                    </div>
                </section>
                <section className="d-flex flex-column gap-3">
                    {
                        program?.image
                            ? <img
                                src={program?.image}
                                alt="program image"
                                width={240}
                            />
                            : null
                    }

                    <ValueWithLabel value={program?.fullName} label="Full name"/>

                    <ValueWithLabel value={program?.author} label="Author"/>

                    <ValueWithLabel value={ENERGY_EQUATION_LABEL[program?.energyEquation]}
                                    label="Energy and nutrient equation"/>

                    <ValueWithLabel value={program?.userSegments?.join(", ")} label="User segments"/>

                    <ValueWithLabel value={program?.restrictions?.join(", ")} label="Restrictions"/>

                    <ValueWithLabel value={program?.allergens?.join(", ")} label="Allergens"/>
                </section>

                <section className="mt-5">
                    <label className="font-weight-semibold font-size-15 mb-4">About program</label>

                    <div className="d-flex flex-column gap-3">
                        <RichTextView label="Short Description" richText={program?.shortDescription}/>

                        <RichTextView label="Long Description" richText={program?.longDescription}/>
                    </div>
                </section>
            </>
        }
    </BaseLayoutWithCard>;
}