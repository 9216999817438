import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { RECOMMENDATIONS_LINKS } from "../config";
import Title from "../../Biomarkers/Details/Title";
import Button from "../../../../base/components/Button";
import { BUTTON_COLORS } from "../../../../base/components/Button/appearance";
import { useNavigate, useParams } from "react-router-dom";
import { replaceUrlParams } from "../../../../base/helpers/url";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import RecommendationsService from "../../../../services/RecommendationsService";
import ValueWithLabel from "../../Biomarkers/Details/ValueWIthLabel";
import {
  CONTRADICTIONS_MAP,
  DOCTOR_CATEGORY_ID, IDEAL_FREQUENCY_MAP,
  IDEAL_TIME_OF_DAY_MAP, SKIN_RECOMMENDATIONS_IDS,
  SKIN_TYPES_MAP, SUPPLEMENT_CATEGORY_ID,
  tableCategories, TEST_CATEGORY_ID,
  typeValues
} from "../CreateEdit/constants";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import RichTextView from "../../Biomarkers/Details/RIchTextView";
import BiomarkersImpacts, { CartPreview } from "../CreateEdit/components";
import ImpactPopupView from "./ImpactView";

const breadcrumbs = {
  title: "recommendation detailed view",
  breadcrumbItems: [
    { title: "Recommendations", link: RECOMMENDATIONS_LINKS.LIST },
    { title: "Recommendation detailed view" },
  ]
}

export default function RecommendationDetails() {
  /**
   * @type {RecommendationsService}
   */
  const recommendationsService = useService(RecommendationsService);

  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, { registerPromise }] = useLoading(true);
  const [recommendation, updateRecommendation] = useState({})
  const [showImpactView, updateShowImpactView] = useState(false);

  const canUseProductLink = useMemo(() => {
    return [TEST_CATEGORY_ID, SUPPLEMENT_CATEGORY_ID, ...SKIN_RECOMMENDATIONS_IDS].includes(recommendation.category)
  }, [recommendation.category, id]);

  const isSkinRecommendation = useMemo(() => SKIN_RECOMMENDATIONS_IDS.includes(recommendation.category),
    [recommendation.category, id]);

  const getRecommendation = useCallback(() => {
    registerPromise(recommendationsService.getRecommendation(id).then(data => {
      updateRecommendation(data);
    }))
  }, [id])

  useEffect(() => {
    getRecommendation()
  }, [getRecommendation])

  return (
    <BaseLayoutWithCard breadcrumbs={breadcrumbs} loading={isLoading || !recommendation.title}>

      {showImpactView &&
        <ImpactPopupView
          isOpen={!!showImpactView}
          onClose={() => updateShowImpactView(false)}
          impact={showImpactView}
          isDoctorOptionChosen={recommendation.category === DOCTOR_CATEGORY_ID}
          isSkinRecommendation={isSkinRecommendation}
        />
      }

      <section className="d-flex align-items-center justify-content-between">
        <Title
          title="General information"
          className=""
        />
        <Button
          color={BUTTON_COLORS.primary}
          onClick={() => navigate(replaceUrlParams(RECOMMENDATIONS_LINKS.EDIT, { id }))}
          className="d-flex align-items-center"
        >
          <i className="bx bx-edit-alt me-2"/>
          Edit recommendation
        </Button>
      </section>

      <img
        src={recommendation.file?.link}
        alt="recommendationView"
        className="recommendation-details__image mt-4"
      />

      <ValueWithLabel
        label="Title"
        value={recommendation.title}
        className="mt-3"
      />

      <ValueWithLabel
        label="Category"
        value={tableCategories[recommendation.category]}
        className="mt-3"
      />

      {recommendation.tagName &&
        <ValueWithLabel
          label="Tag"
          value={recommendation.tagName}
          className="mt-3"
        />
      }

      {recommendation.productLink && canUseProductLink &&
        <>
          <section className={joinClassNames("d-flex flex-column mt-3")}>
            <label className="font-weight-semibold mb-1">Product link</label>
            <a
              className="text-primary font-weight-normal mb-0 text-wrap word-break"
              href={recommendation.productLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {recommendation.productLink}
            </a>
          </section>

          <CartPreview
            title={recommendation.title}
            image={recommendation.file?.link}
            className="w-50"
            showButton={recommendation.isAddToCartAllowed}
          />
        </>
      }

      {recommendation.type &&
        <ValueWithLabel
          label="Type"
          value={typeValues[recommendation.type]}
          className="mt-3"
        />
      }

      {recommendation.idealFrequency &&
        <ValueWithLabel
          label="Ideal frequency"
          value={IDEAL_FREQUENCY_MAP[recommendation.idealFrequency].value}
          className="mt-3"
        />
      }

      {recommendation.idealTimeOfDay &&
        <ValueWithLabel
          label="Best time of the day"
          value={IDEAL_TIME_OF_DAY_MAP[recommendation.idealTimeOfDay].value}
          className="mt-3"
        />
      }

      {!!recommendation.idealSkinTypes?.length &&
        <ValueWithLabel
          label="Ideal skin type"
          value={recommendation.idealSkinTypes.map(({ skinType }) => SKIN_TYPES_MAP[skinType].text).join(", ")}
          className="mt-3"
        />
      }

      {!!recommendation.notMeantForSkinTypes?.length &&
        <ValueWithLabel
          label="Not meant for skin type"
          value={recommendation.notMeantForSkinTypes.map(({ skinType }) => SKIN_TYPES_MAP[skinType].text).join(", ")}
          className="mt-3"
        />
      }

      {recommendation.contradictions?.length &&
        <ValueWithLabel
          label="Contraindications"
          value={recommendation.contradictions.map(({ contradictionType }) => CONTRADICTIONS_MAP[contradictionType]?.text).join(", ")}
          className="mt-3"
        />
      }

      {recommendation.content &&
        <RichTextView
          label="Description"
          richText={recommendation.content}
          containerClassName="mt-3"
        />
      }

      {!!recommendation.impacts?.length &&
        <>
          <Title
            title="Specific impact on biomarker"
            className="mt-5 mb-4"
          />

          <BiomarkersImpacts
            biomarkersImpacts={recommendation.impacts}
            className="w-50"
            withView
            onView={(impact) => {
              updateShowImpactView(impact)
            }}
          />
        </>
      }

    </BaseLayoutWithCard>
  )
}
