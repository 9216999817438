import Http from "./Http";

class TagService extends Http {
    static $displayName = "TagService";

    async getTags(params) {
        return await this.get(`/tags`, { params });
    }

    createTag(values) {
        return this.post(`/tags`, values);
    }
}

export default TagService;