import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import Breadcrumb from "../../../base/components/Common/Breadcrumb2";
import ReportsTable from "./components/ReportsTable";

const breadcrumbs = {
  title: "Dashboard", breadcrumbItems: []
}

export default function Dashboard() {
  return (
    <Container fluid className="content">
      <Breadcrumb {...breadcrumbs} />
      <Card>
        <CardBody>
          <ReportsTable />
        </CardBody>
      </Card>
    </Container>
  );
}