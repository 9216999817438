
export const WELLNESS_TYPE = {
    FITNESS: 1,
    WELLNESS: 2
}

export const PROGRAM_LEVEL = {
    BEGINNER: 2,
    INTERMEDIATE: 4,
    ADVANCED: 1,
    CHALLENGER: 3,
}

export const PROGRAM_LEVEL_LABEL = {
    [PROGRAM_LEVEL.BEGINNER]: "Beginner",
    [PROGRAM_LEVEL.INTERMEDIATE]: "Intermediate",
    [PROGRAM_LEVEL.ADVANCED]: "Advanced",
    [PROGRAM_LEVEL.CHALLENGER]: "Challenger"
}

export const programLevelOptions = Object.values(PROGRAM_LEVEL).map((value) => ({
    value,
    id: value,
    label: PROGRAM_LEVEL_LABEL[value]
}));

export const PROGRAM_STATUSES = {
    ACTIVE: 1,
    INACTIVE: 2
}

export const PROGRAM_STATUSES_LABELS = {
    [PROGRAM_STATUSES.ACTIVE]: 'Active',
    [PROGRAM_STATUSES.INACTIVE]: 'Deactivated'
}

export const programStatusOptions = Object.entries(PROGRAM_STATUSES_LABELS).map(([key, value]) => ({
    value: key,
    id: key,
    label: value
}))

export const PROGRAM_PLAN_CONTENT_TYPE = {
    WORKOUT: 1,
    VIDEO: 2,
    RECIPE: 3,
    AUDIO: 4,
    ARTICLE: 5,
};

export const PROGRAM_PLAN_CONTENT_TYPE_LABELS = {
    [PROGRAM_PLAN_CONTENT_TYPE.WORKOUT]: 'Workout',
    [PROGRAM_PLAN_CONTENT_TYPE.VIDEO]: 'Video',
    [PROGRAM_PLAN_CONTENT_TYPE.RECIPE]: 'Recipe',
    [PROGRAM_PLAN_CONTENT_TYPE.AUDIO]: 'Audio',
    [PROGRAM_PLAN_CONTENT_TYPE.ARTICLE]: 'Article',
};

export const CONTENT_TYPE_API_ID = {
    [PROGRAM_PLAN_CONTENT_TYPE.VIDEO]: 3,
    [PROGRAM_PLAN_CONTENT_TYPE.AUDIO]: 2,
    [PROGRAM_PLAN_CONTENT_TYPE.ARTICLE]: 1,
};

export const programContentTypeOptions = Object.entries(PROGRAM_PLAN_CONTENT_TYPE_LABELS).map(([key, value]) => ({
    value: key,
    id: key,
    label: value
}));


