import React from 'react';
import Icon from "../../../../base/components/Icon";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import {
  categoryOptions, CONTRADICTIONS_MAP, IDEAL_FREQUENCY,
  IDEAL_TIME_OF_DAY,
  IMPACT_TYPES, SKIN_IMPACT_TAB, SKIN_RECOMMENDATIONS_IDS,
  SKIN_TYPES_MAP, SUPPLEMENT_CATEGORY_ID, TEST_CATEGORY_ID,
  typeOptions
} from "./constants";

export default function BiomarkersImpacts({
  biomarkersImpacts = [],
  withDelete,
  onDelete,
  withEdit,
  onEdit,
  withView,
  onView,
  className,
}) {
  if(!biomarkersImpacts.length) return null;
  return (
    <section className={joinClassNames("impacts-section", className)}>
      {biomarkersImpacts.map((impact, index) => {
        const name = impact.biomarker?.name ?? impact.biomarkerName
        return (
          <section key={index} className="d-flex align-items-center justify-content-between impacts-section__item">
            <section className="d-flex align-items-center">
              <label className="impacts-section__index mb-0">{index + 1}.</label>
              <label className="impacts-section__name mb-0">{name}</label>
            </section>

            <section className="d-flex align-items-center">
              {withView && <Icon icon="eye" className="impacts-section__view" onClick={() => onView(impact)}/>}
              {withEdit && <Icon icon="edit" className="impacts-section__edit" onClick={() => onEdit(impact)}/>}
              {withDelete && <Icon icon="trashIcon" className="cursor-pointer" onClick={() => onDelete(impact)}/>}
            </section>
          </section>
        )
      })}
    </section>
  )
}

export const ImpactPopupNav = ({ activeImpactTab, updateActiveImpactTab, isSkinRecommendation }) => {
  const tabs = isSkinRecommendation ? SKIN_IMPACT_TAB : IMPACT_TYPES;
  return (
    <Nav tabs className={joinClassNames(
      "nav-tabs-custom nav-justified mt-3",
      isSkinRecommendation && "px-4"
    )}>
      {tabs.map(({ label, value }) => (
        <NavItem key={label} className={joinClassNames(
          isSkinRecommendation && "mx-5"
        )}>
          <NavLink
            className={classnames({
              active: activeImpactTab === value,
            }, "cursor-pointer")}
            onClick={() => {
              if(activeImpactTab === value) return;
              updateActiveImpactTab(value)
            }}
          >
            <span>{label}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}

export const transformRecommendation = ({
  file,
  category,
  type,
  tagName,
  impacts,
  idealTimeOfDay,
  idealFrequency,
  idealSkinTypes,
  notMeantForSkinTypes,
  contradictions,
  ...data
}) => {
  return {
    ...data,
    file: [{
      ...file,
      preview: file.link,
      file: { size: file.bytes ?? 0 },
      cropped: true,
    }],
    category: categoryOptions.find(({ id }) => id === category),
    customTagName: tagName,
    type: typeOptions.find(({ id }) => id === type),
    idealTimeOfDay: IDEAL_TIME_OF_DAY.find(({ id }) => id === idealTimeOfDay),
    idealFrequency: IDEAL_FREQUENCY.find(({ id }) => id === idealFrequency),
    idealSkinTypes: idealSkinTypes?.map(({ skinType }) => SKIN_TYPES_MAP[skinType]),
    notMeantForSkinTypes: notMeantForSkinTypes?.map(({ skinType }) => SKIN_TYPES_MAP[skinType]),
    contradictions: contradictions?.map(({ contradictionType }) => CONTRADICTIONS_MAP[contradictionType]).filter(contradiction => !!contradiction),
    impacts: impacts?.map(({
      biomarkerId,
      biomarkerName,
      id,
      recommendationId,
      updatedAt,
      createdAt,
      ...rest
    }) => ({
      ...rest,
      biomarker: {
        id: biomarkerId,
        name: biomarkerName,
      }
    }))
  }
}

export const CartPreview = ({
  title,
  image,
  className,
  showButton,
}) => {
  return (
    <section className={joinClassNames(className, "recommendation-preview")}>
      <section className="d-flex">
        {image && <img className="recommendation-preview__image" src={image} alt="cartPreview"/>}
        <section className="d-flex flex-column">
          <label className="recommendation-preview__title">{title}</label>
          {showButton &&
            <Icon icon="cartButton" className=""/>
          }
        </section>
      </section>
    </section>
  )
}

export const prepareRecommendation = ({
  category,
  type,
  impacts = [],
  customTagName,
  id,
  updatedAt,
  createdAt,
  isDeletable,
  isArchived,
  idealTimeOfDay,
  idealFrequency,
  isAddToCartAllowed,
  productLink,
  idealSkinTypes = [],
  notMeantForSkinTypes = [],
  contradictions = [],
  ...otherValues
}) => {

  const isSkinRecommendation = SKIN_RECOMMENDATIONS_IDS.includes(category.id);
  const canUseProductLink = [TEST_CATEGORY_ID, SUPPLEMENT_CATEGORY_ID, ...SKIN_RECOMMENDATIONS_IDS].includes(category.id);

  const preparedValues = {
    ...otherValues,
    category: category.id,
    tagName: customTagName,
    type: type?.id,
    impacts: impacts.map(({ biomarker, lowStudyLinks, highStudyLinks, ...otherImpactData }) => {
      return {
        ...otherImpactData,
        biomarkerId: biomarker.id,
        lowStudyLinks: lowStudyLinks?.filter(({ content }) => !!content).map(({ title, id, createdAt, updatedAt, recommendationImpactId, type, ...other }) => ({ ...other, title: title || null })),
        highStudyLinks: highStudyLinks?.filter(({ content }) => !!content).map(({ title, id, createdAt, updatedAt, recommendationImpactId, type, ...other }) => ({ ...other, title: title || null })),
      }
    }),
  }

  if(canUseProductLink) {
    preparedValues.productLink = productLink;
    if(productLink) {
      preparedValues.isAddToCartAllowed = isAddToCartAllowed;
    }
  }

  if(isSkinRecommendation) {
    preparedValues.idealTimeOfDay = idealTimeOfDay?.id;
    preparedValues.idealFrequency = idealFrequency?.id;
    preparedValues.idealSkinTypes = idealSkinTypes.map(({ id }) => id);
    preparedValues.notMeantForSkinTypes = notMeantForSkinTypes.map(({ id }) => id);
    preparedValues.contradictions = contradictions.map(({ id }) => id);
  }

  return preparedValues
}
