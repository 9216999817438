import Http from "./Http";

class ContentsService extends Http {
    static $displayName = "ContentsService";

    async getContents(params) {
        return await this.get(`/admins/contents`, { params });
    }
}

export default ContentsService;
