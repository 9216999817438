import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocationQuery, useQueryString } from "../../../../base/hooks/useQueryString";
import { CATEGORY_LABELS, CUSTOMER_RESULTS_CATEGORIES } from "../../CustomerResults/constants";
import { formatISODate } from "../../../../base/helpers/date";
import { CATEGORIES, DNA_CATEGORY, FOOD_INTOLERANCE_CATEGORY, STATUS_IDS } from "../../../../base/constants/samples";
import Icon from "../../../../base/components/Icon";
import { UncontrolledTooltip } from "reactstrap";
import joinClassNames from "../../../../base/helpers/joinClassNames";
import {
  BUTTONS_FOR_STATUSES,
  DOWNLOAD_FILE,
  MARK_AS_CANCELLED,
  MARK_AS_RECEIVED_BY_LAB,
  MARK_AS_VERIFIED,
  REPROCESS_CSV_FILE,
  REPROCESS_KIT_OBJECT,
  THREE_DOT_TYPES,
  ThreeDotDropdown,
  UPLOAD_CSV_FILE,
  VIEW_CANCELLATION_REASON,
  VIEW_CSV_FILE,
  VIEW_RESULTS
} from "../../CustomerResults/ResultsList/components";
import TableSearch from "../../../../base/components/Table/tableSearch";
import Table from "../../../../base/components/Table";
import {
  parseSorting, useFilterProvider,
  useLocationSource,
  usePaginationProvider,
  useSearchProvider, useSortProvider
} from "../../../../base/components/Table/hooks";
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_TABLE_LIMIT } from "../../../../base/constants/shared";
import { useService } from "../../../../base/hooks/useService";
import UsersService from "../../../../services/UsersService";
import UserResultsService from "../../../../services/UserResults";
import ToasterService from "../../../../services/ToastService";
import { useLocation, useParams } from "react-router-dom";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { useLoading } from "../../../../base/hooks/useLoading";
import PDFFilePopup from "../../CustomerResults/ResultsList/PDFFilePopup";
import ConfirmPopup, { ConfirmPopupWithReason } from "../../../../base/components/ConfirmPopup";
import FilePopup from "../../CustomerResults/ResultsList/FilePopup";
import FoodCsvViewer from "../../CustomerResults/ResultsList/FoodCsvViewer";
import DNACsvViewer from "../../CustomerResults/ResultsList/DNACsvViewer";
import { ERROR_TYPES } from "../../../../base/constants/errors";
import ResultsPopupBlood from "../../CustomerResults/ResultsList/ResultsPopupBlood";
import ResultsPopupFoodIntolerance from "../../CustomerResults/ResultsList/ResultsPopupFoodIntolerance";
import { LIMIT_OPTIONS } from "../../../../base/constants/pagination";

const resultPopupComponents = {
  [CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY]: ResultsPopupBlood,
  [CUSTOMER_RESULTS_CATEGORIES.DNA_CATEGORY]: ResultsPopupBlood,
  [CUSTOMER_RESULTS_CATEGORIES.FOOD_INTOLERANCE_CATEGORY]: ResultsPopupFoodIntolerance,
}

const Hl7ObjectStatuses = {
  1: 'new',
  2: 'inProgress',
  3: 'error',
  4: 'verified',
  5: 'canceled',
  6: 'processedByInitialLab',
};

const StatusLabel = ({ status }) => {
  return <div className={`lab-result-status-tag-${status}`}>{status}</div>;
};

const columns = [
  {
    Header: '#',
    width: 50,
    className: 'first-sticky-column',
    headerClassName: 'first-sticky-column',
    Cell: ({ row: { index } }) => {
      const {
        params: { offset = 0 },
      } = useLocationQuery();
      return <label className="mb-0">{index + 1 + (offset ?? 0)}</label>;
    },
  },
  {
    Header: 'Product Name',
    accessor: 'testProductName',
    className: 'second-sticky-column',
    headerClassName: 'second-sticky-column',
    width: 200,
    canSort: true,
    Cell: ({ value }) => {
      return (
        <section className="w-fit-content d-flex align-items-center">
          {value}
        </section>
      );
    },
  },
  {
    Header: 'Order ID',
    accessor: 'orderId',
    canSort: true,
    minWidth: 120,
    Cell: ({ value }) => {
      return (
        <section className="d-flex justify-content-between align-items-center">
          {value}
        </section>
      );
    },
  },
  {
    Header: 'Category',
    accessor: 'category',
    width: 180,
    Cell: ({ value, row: { original } }) => {
      // -- if sampleCode is null -> claim this result as manually uploaded PDF result --
      if (!original?.sampleCode) {
        value = CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY;
      }
      return <div className="w-100 text-truncate">{CATEGORY_LABELS[value]}</div>;
    },
  },
  {
    Header: 'Sample ID',
    accessor: "sampleCode",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Activ. date',
    accessor: "activatedAt",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Sample date',
    accessor: "sampleAt",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Lab rec. date',
    accessor: "labReceivedAt",
    minWidth: 160,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Result date',
    accessor: "resultAt",
    minWidth: 130,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{formatISODate(value)}</section>,
  },
  {
    Header: 'Test ID',
    accessor: "id",
    minWidth: 110,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Lab name',
    accessor: "lab",
    minWidth: 130,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Lab ID',
    accessor: "labId",
    minWidth: 100,
    canSort: true,
    Cell: ({ value }) => <section className="d-flex justify-content-between align-items-center">{value}</section>,
  },
  {
    Header: 'Status',
    className: 'before-last-sticky-column',
    accessor: 'status',
    width: 100,
    canSort: true,
    Cell: ({
             value,
             row: {
               original: { isCriticalResult, toFollow, id },
             },
           }) => {
      const isErrorStatus = value === STATUS_IDS.error;
      
      return (
        <div className="d-flex align-items-center">
          <StatusLabel status={Hl7ObjectStatuses[value]} />
          {isCriticalResult && !isErrorStatus && <Icon icon="isCriticalResult" className="ms-2" />}
          {isErrorStatus && !!toFollow &&
            <>
              <Icon icon="infoCircle" className="ms-2" id={`resultErrorMessage-${id}`}/>
              <UncontrolledTooltip
                popperClassName={joinClassNames(
                  "tooltip-alternative-name error-result-tooltip",
                )}
                innerClassName="pre-line text-truncate error-inner-max-height"
                placement="bottom"
                target={`resultErrorMessage-${id}`}
              >
                {toFollow}
              </UncontrolledTooltip>
            </>
          }
        </div>
      );
    },
  },
  {
    Header: () => <div className="">Actions</div>,
    className: 'last-sticky-column',
    accessor: 'actions',
    width: 75,
    Cell: ({
             row: {
               original: { id, status, category, ...rest },
             },
             actions,
           }) => {
      const [isOpen, updateIsOpen] = useState(false);
      const { markAsCancelled, markAsVerified, viewFiles, viewResults, reprocessHL7, viewCancellationReason, downloadFile, markAsReceivedByLab, uploadCSVFile, viewCSVFile, reprocessCSVFile } = actions;
      
      const BUTTONS_FOR_STATUSES_BY_FOOD = [
        [],
        [MARK_AS_RECEIVED_BY_LAB, MARK_AS_CANCELLED],
        [UPLOAD_CSV_FILE, MARK_AS_CANCELLED],
        [VIEW_CSV_FILE, MARK_AS_CANCELLED, MARK_AS_VERIFIED, REPROCESS_CSV_FILE],
        [VIEW_CSV_FILE, VIEW_RESULTS, DOWNLOAD_FILE],
        [VIEW_CSV_FILE, VIEW_CANCELLATION_REASON],
      ]
      
      const BUTTONS_FOR_DNA_STATUSES = [
        [],
        [MARK_AS_CANCELLED],
        [MARK_AS_CANCELLED],
        [MARK_AS_CANCELLED, REPROCESS_KIT_OBJECT],
        [VIEW_CSV_FILE, VIEW_RESULTS],
        [],
        [MARK_AS_CANCELLED]
      ]
      
      const menuActions = {
        [THREE_DOT_TYPES.view]: () => viewFiles({ id, status, ...rest }),
        [THREE_DOT_TYPES.markAsCancelled]: () => markAsCancelled(id),
        [THREE_DOT_TYPES.markAsVerified]: () => markAsVerified(id),
        [THREE_DOT_TYPES.reprocess]: () => reprocessHL7({ id, ...rest }),
        [THREE_DOT_TYPES.viewResults]: () => viewResults({ id, status, category, ...rest }),
        [THREE_DOT_TYPES.viewCancellationReason]: () => viewCancellationReason(rest.cancellationReason),
        [THREE_DOT_TYPES.markAsReceivedByLab]: () => markAsReceivedByLab(id),
        [THREE_DOT_TYPES.uploadCSVFile]: () => uploadCSVFile({ id, status, category, ...rest }),
        [THREE_DOT_TYPES.viewCSVFile]: () => viewCSVFile({ status, id, category, ...rest }),
        [THREE_DOT_TYPES.reprocessCSVFile]: () => reprocessCSVFile({ id, status, ...rest }),
        [THREE_DOT_TYPES.downloadFile]: () => downloadFile(rest?.pdfResultFile?.link)
      };
      
      let menuOptions = [];
      
      // -- options for Food Intolerance --
      if (CATEGORIES[category] === FOOD_INTOLERANCE_CATEGORY) {
        menuOptions = BUTTONS_FOR_STATUSES_BY_FOOD[status];
      }
      
      if (CATEGORIES[category] === DNA_CATEGORY) {
        menuOptions = BUTTONS_FOR_DNA_STATUSES[status];
      }
      
      // -- options for Manually uploaded PDF Results --
      if (!rest?.sampleCode) {
        menuOptions = [
          VIEW_RESULTS,
          { ...DOWNLOAD_FILE, disabled: rest?.pdfResultFile?.link === undefined}
        ];
      }
      
      // --  options for HL7 Results --
      if (!menuOptions.length) {
        menuOptions = BUTTONS_FOR_STATUSES[status];
      }
      
      
      return (
        <section className={joinClassNames('d-flex align-items-center justify-content-center text-primary', isOpen && 'force-z-index')}>
          <ThreeDotDropdown
            id={id}
            isOpen={isOpen}
            updateIsOpen={updateIsOpen}
            options={menuOptions}
            onSelect={type => {
              menuActions[type]();
            }}
          />
        </section>
      );
    },
  },
];

const SearchPlaceholder = () => (
  <>
    No lab results found.
  </>
);

const TableHeader = ({ searchProvider }) => {
  return (
    <section className="d-flex flex-column">
      <section className="d-flex w-100 mb-3 align-items-center justify-content-between">
        <label className="font-weight-semibold mb-1">Lab Test Results</label>
        <TableSearch
          className="biomarkers-search me-3"
          search={searchProvider.getValue()}
          onSearch={searchProvider.setValue}
          placeholder="Search product name, order ID or sample ID"
        />
      </section>
    </section>
  );
};

export default function LabResultsList({ setIsCsvPopupOpen }) {
  /**
   * @type {UsersService}
   */
  const usersService = useService(UsersService);
  /**
   * @type {UserResultsService}
   */
  const userResultsService = useService(UserResultsService);
  /**
   * @type {ToasterService}
   */
  const toastService = useService(ToasterService);
  
  const { id } = useParams();
  const { search: locationSearch } = useLocation();
  
  const [collapsedRows, updateCollapsedRows] = useState([]);
  const [labResults, setLabResults] = useState([]);
  const [resultsPagination, setResultsPagination] = useState([]);
  const [isOpenDetailsPopup, updateIsOpenDetailsPopup] = useState(false);
  const [isOpenPDFFilesPopup, updateIsOpenPDFFilesPopup] = useState(false);
  const [showConfirmVerifyPopup, updateShowConfirmVerifyPopup] = useState(false);
  const [showCancelVerifyPopup, updateShowCancelVerifyPopup] = useState(false);
  const [cancellationReason, updateCancellationReason] = useState();
  const [isOpenCancellationReasonPopup, updateIsOpenCancellationReasonPopup] = useState(false);
  const [viewFoodCsvRow, setViewFoodCsvRow] = useState(false);
  const [viewDNACsvRow, setViewDNACsvRow] = useState(false);
  const [userResultDetails, updateUserResultDetails] = useState({});
  const [isOpenResultsPopup, updateIsOpenResultsPopup] = useState(false);
  const [reprocessRow, updateReprocessRow] = useState(false);
  
  const locationSource = useLocationSource();
  const [isTableLoading, { registerPromise: registerTablePromise }] = useLoading(true);
  
  const ResultPopup = useMemo( () => {
    // -- if sampleCode is not exist -> it's manually uploaded PDF Results --
    if (typeof isOpenResultsPopup == "object" && !isOpenResultsPopup?.sampleCode) {
      isOpenResultsPopup.category = CUSTOMER_RESULTS_CATEGORIES.BLOOD_CATEGORY;
    }
    return resultPopupComponents[isOpenResultsPopup.category]
  }, [isOpenResultsPopup])
  
  const {
    params: {
      search,
      limit = DEFAULT_TABLE_LIMIT,
      offset = 0,
      testProductName,
      orderId,
      sampleCode,
      activatedAt,
      sampleAt,
      labReceivedAt,
      resultAt,
      id: testId,
      lab,
      labId,
      status
    }
  } = useQueryString(locationSearch)
  
  const paginationProvider = usePaginationProvider({
    source: locationSource,
    alias: "offset",
    scope: "",
    fallback: 0
  });
  
  const limitProvider = usePaginationProvider({
    source: locationSource,
    alias: "limit",
    scope: "",
    fallback: 15,
    onApplyClearScope: ["offset"]
  });
  
  const searchProvider = useSearchProvider({
    source: locationSource,
    scope: "",
    alias: 'search',
    onApplyClearScope: [""]
  });
  
  const filterProvider = useFilterProvider({
    source: locationSource,
    scope: "",
    alias: 'categoryIds',
    onApplyClearScope: ["offset"]
  })
  
  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_DELAY, () => paginationProvider.setValue(0));
  
  const fetchTable = useCallback(
    () => {
      const sortObject = parseSorting({
        testProductName,
        orderId,
        sampleCode,
        activatedAt,
        sampleAt,
        labReceivedAt,
        resultAt,
        id: testId,
        lab,
        labId,
        status
      });
      const params = {
        limit,
        offset,
        q: debouncedSearch,
        withErrorNotifications: 1,
        ...sortObject
      };
      return registerTablePromise(
        usersService
          .getUserLabsResults(id, params)
          .then(({ data, pagination }) => {
            setLabResults(data);
            setResultsPagination(pagination);
          })
      );
    },
    [
      id,
      offset,
      limit,
      testProductName,
      orderId,
      sampleCode,
      activatedAt,
      sampleAt,
      labReceivedAt,
      resultAt,
      testId,
      lab,
      labId,
      status,
      debouncedSearch
    ],
  )
  
  const generateSortProviderParams = (name) => {
    const allParams = [
      "testProductName",
      "orderId",
      "sampleCode",
      "activatedAt",
      "sampleAt",
      "labReceivedAt",
      "resultAt",
      "id",
      "lab",
      "labId",
      "status"
    ];
    return {
      source: locationSource,
      alias: name,
      scope: "",
      onApplyClearScope: allParams.filter(paramName => paramName !== name)
    }
  }
  
  const nameSortProvider = useSortProvider(generateSortProviderParams("testProductName"));
  const orderIdSortProvider = useSortProvider(generateSortProviderParams("orderId"));
  const sampleCodeSortProvider = useSortProvider(generateSortProviderParams("sampleCode"));
  const activatedAtSortProvider = useSortProvider(generateSortProviderParams("activatedAt"));
  const sampleAtSortProvider = useSortProvider(generateSortProviderParams("sampleAt"));
  const labReceivedAtSortProvider = useSortProvider(generateSortProviderParams("labReceivedAt"));
  const resultAtSortProvider = useSortProvider(generateSortProviderParams("resultAt"));
  const idSortProvider = useSortProvider(generateSortProviderParams("id"));
  const labSortProvider = useSortProvider(generateSortProviderParams("lab"));
  const labIdSortProvider = useSortProvider(generateSortProviderParams("labId"));
  const statusSortProvider = useSortProvider(generateSortProviderParams("status"));
  
  const toggleCollapse = (id) => {
    if (collapsedRows.includes(id)) {
      updateCollapsedRows(prevState => prevState.filter(item => item !== id))
      return
    }
    updateCollapsedRows(prevState => [...prevState, id]);
  }
  
  const handleMarkAsReceivedByLab = (id) => {
    return userResultsService.receivedByLab(id)
      .then(() => {
        fetchTable()
        toastService.success("Kit status updated to In Progress")
      })
  }
  
  const reprocessHL7 = useCallback((row) => {
    registerTablePromise(userResultsService.reprocessHL7(row.id).then(fetchTable))
  }, [id])
  
  const changeUserResultStatus = useCallback((id, status, additionalValues = {}) => {
    registerTablePromise(userResultsService.changeResultStatus(id, { status, ...additionalValues })
      .then(fetchTable))
  }, [])
  
  const reprocessKitObjectHasRelatedErrorsNote = useMemo(() => {
    const errorNotifications = reprocessRow?.errorNotifications
    if (!errorNotifications || !errorNotifications.length) {
      return ''
    }
    
    const notificationErrorType = errorNotifications[errorNotifications.length - 1]?.errorType;
    if (!notificationErrorType) {
      return ''
    }
    
    return [
      ERROR_TYPES.eurofinCsvSampleNotFound,
      ERROR_TYPES.s3CsvUploadError,
      ERROR_TYPES.clockFoundationCsvUploadError,
      ERROR_TYPES.s3RawUploadError,
      ERROR_TYPES.clockFoundationRawUploadError
    ].includes(notificationErrorType) ? ' Please confirm DNA sample reprocessing. All the samples from the batch will be reprocessed at the same time.' : '';
  }, [reprocessRow?.id]);
  
  useEffect(() => {
    fetchTable()
  }, [fetchTable]);
  
  return (
    <>
      <Table
        columns={columns}
        data={labResults}
        isAddNewBiomarker
        loading={isTableLoading}
        HeaderComponent={TableHeader}
        totalCount={resultsPagination.totalCount}
    
        searchProvider={searchProvider}
        filterProvider={filterProvider}
        sortProviders={{
          ['testProductName']: nameSortProvider,
          ['orderId']: orderIdSortProvider,
          ['sampleCode']: sampleCodeSortProvider,
          ['activatedAt']: activatedAtSortProvider,
          ['sampleAt']: sampleAtSortProvider,
          ['labReceivedAt']: labReceivedAtSortProvider,
          ['resultAt']: resultAtSortProvider,
          ['id']: idSortProvider,
          ['lab']: labSortProvider,
          ['labId']: labIdSortProvider,
          ['status']: statusSortProvider,
        }}
        actions={{
          markAsCancelled: (id) => updateShowCancelVerifyPopup(id),
          markAsVerified: (id) => updateShowConfirmVerifyPopup(id),
          downloadFile: (url) => url ? window.open(url, '_blank').focus() : undefined,
          viewFiles: (row) => {
            updateUserResultDetails(row)
            updateIsOpenDetailsPopup(true);
          },
          markAsReceivedByLab: handleMarkAsReceivedByLab,
          viewCSVFile: (row) => {
            if (row.category === CATEGORIES.FOOD_INTOLERANCE_CATEGORY) {
              setViewFoodCsvRow(row)
            } else {
              setViewDNACsvRow(row)
            }
          },
          uploadCSVFile: (row) => { setIsCsvPopupOpen(row) },
          viewResults: (row) => updateIsOpenResultsPopup(row),
          reprocessCSVFile: (row) => { setIsCsvPopupOpen(row) },
          reprocessHL7: (row) => updateReprocessRow(row),
          viewCancellationReason: (reason) => {
            updateCancellationReason(reason);
            updateIsOpenCancellationReasonPopup(true);
          },
      
        }}
    
        limit={limitProvider.getValue()}
        limitProvider={limitProvider}
        offset={offset}
        paginationProvider={paginationProvider}
        commonPlaceholder="No lab results found"
        placeholderForSearch={<SearchPlaceholder/>}
        withLocation
        collapsedState={collapsedRows}
        toggleCollapse={toggleCollapse}
        isLimitEditable={true}
        limitOptions={LIMIT_OPTIONS}
      />
  
      {!!isOpenDetailsPopup &&
        <FilePopup
          isOpen={isOpenDetailsPopup}
          updateIsOpen={updateIsOpenDetailsPopup}
          details={userResultDetails}
          afterOnClose={updateUserResultDetails}
        />
      }
      
      {!!isOpenPDFFilesPopup &&
        <PDFFilePopup
          isOpen={isOpenPDFFilesPopup}
          updateIsOpen={updateIsOpenPDFFilesPopup}
          user={user}
          afterOnClose={updateUserResultDetails}
        />
      }
  
      {!!showConfirmVerifyPopup &&
        <ConfirmPopup
          isOpen={!!showConfirmVerifyPopup}
          updateIsOpen={updateShowConfirmVerifyPopup}
          onSubmit={() => changeUserResultStatus(showConfirmVerifyPopup, STATUS_IDS.verified)}
          title="Confirmation"
          description="Are you sure you want to mark the result as verified?"
          submitBtnText="Confirm"
          className=""
        />
      }
  
      {!!showCancelVerifyPopup &&
        <ConfirmPopupWithReason
          isOpen={!!showCancelVerifyPopup}
          updateIsOpen={updateShowCancelVerifyPopup}
          onSubmit={(values) => changeUserResultStatus(showCancelVerifyPopup, STATUS_IDS.cancelled, values)}
          title="Confirmation"
          description="Are you sure you want to mark the result as canceled?"
          submitBtnText="Confirm"
          className=""
        />
      }
  
      {!!reprocessRow &&
        <ConfirmPopup
          isOpen={!!reprocessRow}
          updateIsOpen={updateReprocessRow}
          onSubmit={() => reprocessHL7(reprocessRow)}
          title="Confirmation"
          description={`Are you sure you want to reprocess kit object file again?${reprocessKitObjectHasRelatedErrorsNote}`}
          submitBtnText="Confirm"
          className=""
        />
      }
  
      {!!isOpenResultsPopup && !!ResultPopup &&
        <ResultPopup
          isOpen={!!isOpenResultsPopup}
          updateIsOpen={updateIsOpenResultsPopup}
          row={isOpenResultsPopup}
        />
      }
  
      {!!isOpenCancellationReasonPopup &&
        <ConfirmPopup
          isOpen={!!isOpenCancellationReasonPopup}
          updateIsOpen={updateIsOpenCancellationReasonPopup}
          onSubmit={() => updateIsOpenCancellationReasonPopup(false)}
          title="Reason of cancellation"
          description={cancellationReason || "-"}
          submitBtnText="Ok"
          className=""
          showCancelButton={false}
          submitButtonClassName="px-4"
        />
      }
  
      <FoodCsvViewer
        updateIsOpen={setViewFoodCsvRow}
        details={viewFoodCsvRow}
      />
  
      <DNACsvViewer
        updateIsOpen={setViewDNACsvRow}
        details={viewDNACsvRow}
      />
    </>
  )
}