// Libs
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Dropdown, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Spinner } from "reactstrap";
import classnames from "classnames";
import { debounce } from "lodash";

// Components, Views, Screens
import Icon from "../../Icon";

// Hooks, Utils, Helpers
import { useService } from "../../../hooks/useService";
import { useLoading } from "../../../hooks/useLoading";
import { useHighlight } from "../../../hooks/useHighlight";
import TagService from "../../../../services/TagService";


// Styles, assets
import classes from "./TagsDropdown.module.scss";

const LIMIT = 100;

export const TagsDropdown = ({ onChange, value = [], placeholder, label, error }) => {
    /**
     * @type {TagService}
     */
    const tagService = useService(TagService);
    const dropdownRef = useRef(null);
    const lastElementRef = useRef(null);
    const [isOpen, updateIsOpen] = useState(false);
    const [tags, setTags] = useState([]);
    const [isLoading, { registerPromise }] = useLoading(true);
    const [inputValue, setInputValue] = useState('');
    const [hasNextPage, setHasNextPage] = useState(true);
    const [page, setPage] = useState(1);

    const { decorateText } = useHighlight(inputValue);

    const showPlaceholder = !value?.length && !isOpen;

    const mapTagsToDropdownOption = (data = []) => {
        return data.map((item) => item.name);
    };

    const filteredTags = useMemo(() => {
        return tags.filter((item) => {
            return !value.includes(item);
        });
    }, [tags, inputValue, value]);


    const addNewItem = () => {
        onChange([inputValue, ...value]);
        setInputValue('');
    };

    const selectNewItem = (item) => {
        onChange([item, ...value]);
        setInputValue('');
    };

    const deleteValue = (deletedItem) => {
        onChange(value.filter(item => item !== deletedItem));
    };

    const loadTags = useCallback(() => {
        const params = {
            limit: LIMIT,
            offset: 0,
            query: inputValue?.trim()
        };

        setHasNextPage(false);

        registerPromise(tagService.getTags(params))
            .then((result => {
                setTags(mapTagsToDropdownOption(result.data));
                setHasNextPage(result.pagination?.nextOffset < result.pagination?.totalCount);
                setPage(1);
            }));
    }, [inputValue]);

    const loadNextPage = () => {
        if (isLoading || !hasNextPage) return;

        const nextPage = page + 1;

        const params = {
            limit: LIMIT,
            offset: (nextPage - 1) * LIMIT,
            query: inputValue?.trim()
        };

        registerPromise(tagService.getTags(params))
            .then(({ data, pagination }) => {
                setTags((prev) => [...prev, ...mapTagsToDropdownOption(data)]);
                setHasNextPage(pagination?.nextOffset < pagination?.totalCount);
                setPage(nextPage);
            });
    };

    const debounceLoadNextPage = debounce(loadNextPage, 300);

    const handleDocumentClick = useCallback((e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            if (isOpen) {
                updateIsOpen(false);
            }
        }
    }, [isOpen]);

    useEffect(() => {
        loadTags();
    }, [loadTags]);

    useEffect(() => {
        document.addEventListener("mousedown", handleDocumentClick);

        return () => {
            document.removeEventListener("mousedown", handleDocumentClick);
        };
    }, [handleDocumentClick]);

    useEffect(() => {
        if (!lastElementRef.current) return;

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                debounceLoadNextPage();
            }
        });

        observer.observe(lastElementRef.current);

        return () => {
            observer.disconnect();
        };
    }, [debounceLoadNextPage, lastElementRef.current]);

    return (
        <>
            {!!label && <label>{label}</label>}
            <section className="d-flex align-items-center w-100" ref={dropdownRef}>
                <Dropdown
                    isOpen={isOpen}
                    toggle={(e) => {
                        if (!isOpen) {
                            updateIsOpen(true);
                        } else if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                            setInputValue('');
                            updateIsOpen(false);
                        }
                    }}
                    className="d-inline-block filter-dropdown cursor-pointer result-filter w-100"
                    direction="down"
                >
                    <DropdownToggle
                        className={classnames(
                            'filter-toggle w-100',
                            { 'with-border': isOpen, "is-invalid select-invalid": !!error })}
                        tag="section"
                    >
                        <span
                            className={classnames('ms-2 me-1 user-select-none text-truncate', { 'text-secondary': showPlaceholder })}
                        >
                            {
                                showPlaceholder
                                    ? placeholder || 'Select tags'
                                    : <ValueItem
                                        values={value}
                                        inputValue={inputValue}
                                        setInputValue={setInputValue}
                                        isInputVisible={isOpen}
                                        addNewItem={addNewItem}
                                        deleteValue={deleteValue}
                                    />
                            }
                        </span>
                        <i className={classnames('mdi mdi-chevron-down pointer-events-none user-select-none', { 'mdi-rotate-180': isOpen })}/>
                    </DropdownToggle>

                    <DropdownMenu className="filter-menu pb-1 px-1 w-100 top-50" flip={false}>
                        <section>
                            <ListGroup>
                                <div className={classnames(classes.ItemsWrapper, "custom-scrollbar")}>
                                    {filteredTags.map((item) => (
                                        <ListGroupItem
                                            className="bg-transparent border-0"
                                            key={item}
                                            onClick={() => selectNewItem(item)}
                                        >
                                            <div {...decorateText(item)} />
                                        </ListGroupItem>
                                    ))}
                                    <div ref={lastElementRef} className="d-flex justify-content-center pb-1"/>
                                    <div className="d-flex justify-content-center">
                                        {isLoading && <Spinner size="xs" color="primary"/>}
                                    </div>
                                </div>
                            </ListGroup>
                        </section>
                    </DropdownMenu>
                </Dropdown>
            </section>
            {error &&
                <div className="invalid-feedback d-block">{error}</div>
            }
        </>
    );
};

function ValueItem({ values, addNewItem, setInputValue, inputValue, isInputVisible, deleteValue }) {

    const handleAddNewTag = (e) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            addNewItem();
        }
    };

    return (
        <div className="d-flex align-items-center overflow-x-auto hide-scrollbar">
            {isInputVisible && (
                <input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleAddNewTag}
                    autoFocus
                    style={{ width: `${inputValue.length + 3}ch` }}
                    className={classnames('d-inline-block px-2 py-0 rounded-1 me-2 h-100 bg-transparent border-1 border-secondary border shadow-none outline-none font-size-11')}
                />
            )}
            {values.map(item => (
                <Badge
                    key={item}
                    color="light"
                    className="d-flex align-items-center bg-transparent border-1 border-secondary border gap-2 me-3 font-size-11 font-weight-normal"
                >
                    {item}
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            deleteValue(item);
                        }}>
                        <Icon
                            icon={'close'}
                            width={8}
                            height={8}
                        />
                    </div>
                </Badge>
            ))}
        </div>
    );
}

