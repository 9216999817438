import { MEAL_TYPES } from "../../../../base/constants/foods";

export const MIN_STRING_LENGTH = 1

export const MAX_AGE = 120
export const MIN_AGE = 18

export const MAX_WEIGHT = 272
export const MIN_WEIGHT = 18

export const MAX_HEIGHT = 250
export const MIN_HEIGHT = 50

export const MAX_CALORIES = 3500
export const MIN_CALORIES = 800

export const MAX_PREPTIME = 720
export const MIN_PREPTIME = 5

export const COOKING_PREFERENCE = {
    HATE: 'very simple',
    MEH: 'simple',
    LIKE: 'medium',
    LOVE: 'complex',
    CHEF: 'very complex'
}

export const COOKING_PREFERENCE_LABEL = {
    [COOKING_PREFERENCE.HATE]: "I hate it!",
    [COOKING_PREFERENCE.MEH]: "It's meh",
    [COOKING_PREFERENCE.LIKE]: "I like it",
    [COOKING_PREFERENCE.LOVE]: "I love it!",
    [COOKING_PREFERENCE.CHEF]: "Yes, chef!"
}

export const PREP_TIME_INPUT_NAMES = {
    [MEAL_TYPES.BREAKFAST]: 'breakfastTime',
    [MEAL_TYPES.LUNCH]: 'lunchTime',
    [MEAL_TYPES.DINNER]: 'dinnerTime',
    [MEAL_TYPES.SNACK]: 'snackTime',
}

export const statusOptions = [
    { id: 5, value: 5, label: "5 min" },
    { id: 15, value: 15, label: "15 min" },
    { id: 30, value: 30, label: "30 min" },
    { id: 45, value: 45, label: "45 min" },
    { id: 60, value: 60, label: "60 min" },
    { id: 90, value: 90, label: "90 min" },
]