import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import { FITNESS_LISTS_TAB_LINKS, FITNESS_LISTS_TABS } from "./constants";
import BaseLayoutWithCard from "../../../../base/components/BaseLayoutWithCard";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Exercises from "./Exercises";
import { FITNESS_LISTS_ROUTES } from "./config";
import { FITNESS_GROUP_LINKS, FITNESS_GROUP_ROUTES } from "../config";
import WorkoutTypes from "./WorkoutTypes";
import Workouts from "./Workouts";


const breadcrumbs = {
    title: "Fitness", breadcrumbItems: []
};

export default function Lists() {
    let navigate = useNavigate();

const location = useLocation()

    return (
        <BaseLayoutWithCard breadcrumbs={breadcrumbs}>
            <Nav tabs className="nav-tabs-custom mt-1">
                {Object.values(FITNESS_LISTS_TABS).map((label, index) => (
                    <NavItem key={`navItem${index}`}>
                        <NavLink
                            className={classNames(
                                {
                                    active: location.pathname === FITNESS_LISTS_TAB_LINKS[label]
                                },
                                "cursor-pointer py-1 mx-1"
                            )}
                            onClick={() => navigate(FITNESS_LISTS_TAB_LINKS[label])}
                        >
                            <span>{label}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <Routes>
                <Route
                    path={FITNESS_LISTS_ROUTES.EXERCISES_LIST}
                    element={<Exercises/>}
                />

                <Route
                    path={FITNESS_LISTS_ROUTES.WORKOUT_TYPES_LIST}
                    element={<WorkoutTypes/>}
                />

                <Route
                    path={FITNESS_LISTS_ROUTES.WORKOUTS_LIST}
                    element={<Workouts />}
                />

                <Route path={"/"} element={<Navigate to={FITNESS_GROUP_LINKS.EXERCISES_LIST} />} />
            </Routes>
        </BaseLayoutWithCard>
);
}
